import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Mobility: Lower Body`}</p>
    <p>{`Technique work: Pistols`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`30-Alternating Step Ups (24/20″, 15/leg)`}</p>
    <p>{`20-Med Ball Cleans (20/14`}{`#`}{`)`}</p>
    <p>{`1:30 Plank`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      